.invoiceList {
  margin: 1em;
  margin-bottom: 80px;

  .totals {
    width: 230px;
    margin: 1em 0;
    padding: 0.5em;
    background-color: #fff;

    h5 { font-size: 1.3em; font-weight: bold; }
  }

  table {
    margin-top: 1em;
    width: 100%;
    background-color: #fff;

    thead th {
      text-align: left;
      font-weight: bold;
      padding: 5px 2px;
    }

    tbody td {
      padding: 10px 2px;
    }
  }
}
