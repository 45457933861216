@use './variables.scss';

html, body {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	color: #000;
	background-color: #F4F8F9;
  height: 100%;
  line-height: 16px;
}

h1 {
	font-size: 2em;
	font-weight: 600;
}

h2 {
	font-size: 1.5em;
	font-weight: 600;
}

h3 {
	font-size: 1.2em;
	font-weight: 600;
}

h4 {
	font-size: 1.1em;
	font-weight: 600;
  margin-bottom: 5px;
}

h5 {
	font-size: 1em;
	font-weight: 600;
}

h6 {
	font-size: 1em;
	font-weight: 300;
}

p {
  margin-top: 5px;
}

strong, b {
  font-weight: 600;
}

em {
  font-style: italic;
}

.pointer {
  cursor: pointer;
}

.no-padding {
	padding: 0 !important;
}

.text-small {
	font-size: 0.85em;
}

.text-small-italic {
	@extend .text-small;
	font-style: italic;
}

.text-grey {
	color: #55545f;
}

.divider {
	margin: 1.2em 0 !important;
}

.contactInfo {
	font-size: 0.9em;
	h6 {
		margin: 1em 0 0.3em 0;
		color: #2f2e3b;
	}
	ul { margin-left: 0.5em; }
	li { padding: 0.3em 0; }
}

.root {
	display: flex;
  flex-direction: column;
  height: 100%;
}
