.BUTTON_GDW {
background: #1E62D0;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
color: #FFFFFF;
font-family: Open Sans;
font-size: 13px;
font-weight: 100;
padding: 4px;
border: solid #337FED 1px;
text-decoration: none;
display: inline-block;
cursor: pointer;
text-align: center;
}

.BUTTON_GDW:hover {
background: #3D94F6;
text-decoration: none;
}
