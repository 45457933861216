.sculpture-single-table {
	tr {
		td:first-child {
			text-align: right;
			padding-right: 0.6em;
			padding-bottom: 0.3em;
			color: #55545f;
		}
	}
}
