body {
  font-size: 14px !important;
  margin-top: 20px;
}

h3 {
  text-transform: uppercase;
  font-size: 18px;
  color: #47763b;
  font-weight: bold;
}

p {
  margin-top: 10px;
}

table {
  width: 100%;

  td {
    padding: 9px 0;
  }

  tr {
    border-top: 1px solid #f1f1f1;
    td:nth-child(2) {
      /*font-weight: bold;*/
    }
  }
}

.footer {
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
  color: #191919;
}

.monospace {
  font-family: "Courier New", Courier, monospace;
}

.bold { font-weight: bold; }

.MuiContainer-root .MuiContainer-maxWidthLg {
  margin: 0;
}

.paid { font-weight: bold; color: #e31c52; }

@media print {
  html, body {
    background-color: #fff;
    margin-top: 20px;
    width: 210mm;
    height: 297mm;
    overflow:hidden !important;
  }

  .root { height: 285mm;}
  body { height: 285mm; margin: 0; padding :0 }
  html { height: 285mm; }
}
